<template>
  <!--支付中弹窗-->
  <van-dialog v-if="showPayLoading" :show="showPayLoading" :title="payType == 'h5' ? '等待支付' : ''"
              confirm-button-text="支付成功" cancel-button-text="取消支付" show-cancel-button
              @confirm="closePayLoading" @cancel="closePayLoading">
    <pay-loading :payUrl="payUrl" :payType="payType" @changeEwmType="changeEwmType"
                 ref="payLoadingDialog"></pay-loading>
  </van-dialog>
  <!--二维码弹窗-->
  <div class="main gray-bg has-bottom" v-else>
    <van-nav-bar left-arrow @click-left="onClickLeft" class="top-bar" title="订单确认"/>
    <div class="header-label">当前钱包余额：<span class="van-ellipsis">{{ payInitData.total_balance }}元</span></div>
    <div class="flex justify-between align-center header-box">
      <div class="head-title">订单金额</div>
      <div class="van-ellipsis head-value">¥{{ payInitData.cost }}</div>
    </div>
    <!--用户资产-->
    <van-cell-group class="cell-list">
      <!--优惠券-->
      <van-cell v-if="payInitData.coupon_number"
                :value="payInitData.coupon_amount ? '-￥'+payInitData.coupon_amount : '0'" is-link
                @click="showCouponVisible = true" :class="payInitData.coupon_amount ? 'cell-check' : ''">
        <template #title>
          <div class="cell-title">优惠券</div>
          <span class="cell-label">可选{{ payInitData.coupon_number }}张</span>
        </template>
      </van-cell>
      <!--优惠券-->
      <!--代金券-->
      <van-cell v-if="payInitData.total_bind && payInitData.bind_status && payInitData.bind_visible"
                :class="checkBind ? 'cell-check' : ''" @click="changeBind(checkBind)">
        <template #title>
          <van-checkbox v-model="checkBind" @click="changeBind(checkBind)">代金券</van-checkbox>
        </template>
        <template #value>{{ payInitData.bind_amount ? '-￥' + payInitData.bind_amount : '￥' + payInitData.total_bind }}
        </template>
      </van-cell>
      <!--代金券-->
      <!--折扣-->
      <van-cell v-if="payInitData.needPay > 0 && payInitData.discount_value && payInitData.discount_value < 10"
                :title="'折扣抵扣（'+payInitData.discount_value+'折)'"
                :value="payInitData.discount_amount ? '-￥'+payInitData.discount_amount : ''"/>
      <!--折扣-->

    </van-cell-group>
    <!--三方支付-->
    <van-radio-group v-model="payInitData.pay_code" v-if="payInitData.needPay > 0">
      <van-cell-group class="cell-list">
        <div v-for="(item, key) in payConfig" :key="key" class="cell-div">
          <van-cell v-if="item.status" clickable @click="choosePayWay(key)">
            <template #title>
              <van-icon :name="item.icon" class="cell-title-img"/>
              <div class="cell-title">{{ item.title }}</div>
            </template>
            <template #right-icon>
              <van-radio :name="key"/>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </van-radio-group>
    <!--三方支付-->
    <!--底部按钮-->
    <div class="bottom-bar flex align-center justify-between">
      <div class="spend-bottom">您还需支付 <span class="fs18">¥</span><span
          class="fs32">{{ Math.floor(payInitData.needPay) }}</span><span
          class="fs16">.{{payInitData.needPay ? subFloatNum(payInitData.needPay) : '00' }}</span>
      </div>
      <van-button type="primary" round @click="doPay" :loading="payLoading"
                  loading-text="支付中...">立即充值
      </van-button>
    </div>
    <!--底部按钮-->
    <!--优惠券弹窗-->
    <van-popup v-if="showCouponVisible" :show="showCouponVisible" class="max-popup">
      <Coupon :coupon="{id:payQuery.coupon_id,amount:payInitData.coupon_amount}"
              :orderNumber="payQuery.pay_order_number" @closeCouponPopup="closeCouponPopup"></Coupon>
    </van-popup>
    <!--优惠券弹窗-->
  </div>
</template>

<script>
import {NavBar, Cell, CellGroup, Button, Checkbox, RadioGroup, Radio, Icon, Popup, Dialog, Toast} from 'vant';
import {reactive, ref} from 'vue';
import {paySpend} from "@/api/pay";
import {payInit} from "@/api/spend";
import {payCallback} from "@/utils/callback";
import PayLoading from "@/views/pay/PayLoading";
import Coupon from "@/views/pay/Coupon";
import {is_wechat} from "@/utils/devices";
import "@/style/common.css"
import {NumberDiv, NumberMul, NumberSub} from "@/utils/math";
import {getStoreItem} from "@/utils/function";


export default {
  name: "PaySpend",
  components: {
    PayLoading,
    Coupon,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const payLoadingDialog = ref();
    // 订单初始化参数
    const payInitData = reactive({
      cost: "0",// 订单金额-原始数据
      pay_amount: "",// 扣除折扣后的支付金额-原始数据
      total_balance: "0",// 平台币余额-原始数据
      total_bind: 0,// 代金券余额-原始数据
      bind_status: '0',// 代金券开关-原始数据
      cp_order_number: '',//CP订单号-原始数据
      discount_value: 10,//折扣-原始数据
      discount_amount: 0,//折扣金额-原始数据
      coupon_number: 0,//优惠券可用数-原始数据
      bind_amount: 0,// 代金券支付金额，不使用代金券支付时展示为余额
      bind_visible: true,// 代金券是否显示
      coupon_amount: 0,//优惠券选中金额
      needPay: 0,// 还需支付金额
      pay_code: 'alipay'
    });
    //代金券选中状态
    const checkBind = ref(false);
    // 支付订单参数
    const payQuery = reactive({
      pay_order_number: getStoreItem('pay_order_number'),
      pay_code: payInitData.pay_code || 'alipay',
      coupon_id: 0,
      use_bind: 0,//是否使用代金券 0/1
      pay_type: 'h5',
    });
    // 优惠券弹窗
    const showCouponVisible = ref(false);
    //支付方式列表
    const payConfig = reactive({
      alipay: {
        title: "支付宝",
        icon: require('@/assets/images/pay/zfb-pay.png'),
        status: 1,
      },
      wechat: {
        title: "微信支付",
        icon: require('@/assets/images/pay/wx-pay.png'),
        status: 1
      },
      balance: {
        title: "钱包支付",
        icon: require('@/assets/images/pay/bill-pay.png'),
        status: 1
      },
      ewm: {
        title: "扫码支付",
        icon: require('@/assets/images/pay/ewm-pay.png'),
        status: 1
      },
    });

    const showPayLoading = ref(false)
    const payLoading = ref(false)
    const payUrl = ref('')
    const payType = ref('')

    // 关闭支付页面
    const onClickLeft = () => {
      payCallback(-1, payInitData.cp_order_number)
    }
    // 初始化订单
    payInit(payQuery.pay_order_number).then(res => {
      // 支付初始化数据
      payInitData.cost = res.data.cost;
      payInitData.pay_amount = res.data.pay_amount;
      payInitData.total_balance = res.data.total_balance;
      payInitData.total_bind = res.data.total_bind;
      payInitData.cp_order_number = res.data.cp_order_number

      let pay_switch;
      ({pay_switch: pay_switch} = res.data);
      // 代金券支付开关
      payInitData.bind_status = pay_switch.bind
      // 支付宝支付开关
      payConfig.alipay.status = pay_switch.alipay
      if (is_wechat()) {
        // 微信浏览器不显示支付宝
        payConfig.alipay.status = '0'
      }
      // 微信支付开关
      payConfig.wechat.status = pay_switch.wechat
      // 平台币支付开关
      payConfig.balance.status = pay_switch.balance

      //折扣
      payInitData.discount_value = res.data.discount
      payInitData.discount_amount = res.data.discount_amount
      //优惠券信息
      let orderCoupon;
      ({coupon: orderCoupon} = res.data);
      payInitData.coupon_number = orderCoupon.coupon_number

      if (orderCoupon.coupon_id) {
        // 选择优惠券
        closeCouponPopup({id: orderCoupon.coupon_id, amount: orderCoupon.coupon_amount})
      } else if (payInitData.bind_status && payInitData.total_bind) {
        changeBind(checkBind.value)
      } else {
        payInitData.needPay = NumberMul(payInitData.cost,NumberDiv(payInitData.discount_value,10,4))
      }
    });
    // 支付订单
    const doPay = () => {
      payLoading.value = true
      // 判断支付方式
      payQuery.pay_type = payQuery.pay_code && payInitData.pay_code == 'ewm' ? 'qr' : 'h5';
      paySpend(payQuery).then(res => {
        const {cp_order_number,pay_code,pay_extend} = res.data;
        if(!pay_code && !pay_extend){
          Toast('支付成功');
          payCallback(1, cp_order_number);
          return false;
        }
        switch (pay_code) {
          case 'alipay':
          case 'wechat':
            payUrl.value = pay_extend;
            payType.value = payQuery.pay_type
            showPayLoading.value = true
            break;
          case 'bind':
          case "balance":
            Toast('支付成功');
            payCallback(1, cp_order_number);
            break;
        }
      }).finally(() => {
        payLoading.value = false
      })
    }

    //订单资产计算
    const computeNeedPay = () => {
      // 扣除优惠券金额
      payInitData.needPay = NumberSub(payInitData.cost, payInitData.coupon_amount)
      // 如果使用代金券 扣除代金券金额
      if (payInitData.needPay > 0 && payQuery.use_bind === 1) {
        // 计算代金券抵扣金额
        payInitData.bind_amount = NumberSub(payInitData.needPay, payInitData.total_bind) > 0 ? payInitData.total_bind : payInitData.needPay;
        payInitData.needPay = NumberSub(payInitData.needPay, payInitData.bind_amount);
      } else {
        payInitData.bind_amount = 0
      }
      if(payInitData.needPay > 0 && payInitData.discount_value && payInitData.discount_value < 10){
        payInitData.discount_amount = NumberSub(payInitData.needPay,NumberMul(payInitData.needPay,NumberDiv(payInitData.discount_value,10,4)))
        payInitData.needPay = NumberMul(payInitData.needPay,NumberDiv(payInitData.discount_value,10,4))
      }
      if (payInitData.needPay > 0) {
        payQuery.pay_code = payInitData.pay_code == 'ewm' ? 'alipay' : payInitData.pay_code;
      }else{
        // 支付金额小于0 显示为0 禁止选择其他支付方式
        payInitData.needPay = 0;
        payQuery.pay_code = ""
      }
    };
    //选择支付方式
    const choosePayWay = (payType) => {
      payQuery.pay_code = payInitData.needPay > 0 ? (payType == 'ewm' ? 'alipay' : payType) : ''
      payInitData.pay_code = payType
    }

    //代金券操作
    const changeBind = (checked) => {
      checkBind.value = !checked
      payQuery.use_bind = checkBind.value ? 1 : 0;
      // 计算待支付金额
      computeNeedPay()
    };

    //关闭优惠券弹窗
    const closeCouponPopup = (data) => {
      // 设置代金券参数
      payQuery.coupon_id = data.id
      // 设置优惠券展示金额
      payInitData.coupon_amount = data.amount
      // 支付金额大于优惠券金额，且开启代金券支付 且 代金券总额大于0
      const can_use_bind = payInitData.cost > payInitData.coupon_amount && payInitData.bind_status && payInitData.total_bind;
      // 是否选中代金券
      checkBind.value = can_use_bind;
      // 设置支付参数 是否代金券
      payQuery.use_bind = can_use_bind ? 1 : 0;
      // 是否代金券数据
      payInitData.bind_visible = can_use_bind;
      // 计算待支付金额
      computeNeedPay();
      showCouponVisible.value = false
    }

    //切换扫码支付
    const changeEwmType = (type) => {
      payQuery.pay_code = type
      paySpend(payQuery).then(res => {
        ({pay_extend: payUrl.value} = res.data);
        payType.value = payQuery.pay_type
        showPayLoading.value = true
      }).finally(() => {
        payLoading.value = false
      })
    }
    //支付成功
    const closePayLoading = () => {
      payLoadingDialog.value.checkOrderStatus(true)
    }

    const subFloatNum = (number) => {
      let substrNumber = number;
      if(number > 0 && number < 0.01){
        substrNumber = 0.01
      }
      const num = NumberMul(substrNumber, 100)
      const index = String(num).indexOf('.')
      return index > -1 ? (index < 2 ? '0'+ String(num).substr(0,1) : String(num).substr(index - 2,2)) : (String(num).length < 2 ? '0' + String(num).slice(-2) : String(num).slice(-2))
    }

    return {
      checkBind,
      payQuery,
      payInitData,
      showPayLoading,
      payLoading,
      payUrl,
      payType,
      payConfig,
      showCouponVisible,
      payLoadingDialog,

      onClickLeft,
      doPay,
      computeNeedPay,
      choosePayWay,
      closeCouponPopup,
      changeBind,
      changeEwmType,
      closePayLoading,
      subFloatNum
    };
  },
}
</script>

<style scoped>
.main {
  overflow-y: auto;
}

.header-label {
  font-size: 14px;
  color: #CC8B00;
  padding: 0px 16px;
  height: 44px;
  line-height: 44px;
  text-align: left;
  background: #FFF6E5;
}

.header-label span {
  color: #CC5800;
}

.header-box {
  padding: 0px 16px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
}

.head-title {
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  line-height: 20px;
}

.head-value {
  font-size: 16px;
  font-weight: 500;
  color: #FF0033;
}

.cell-list {
  margin-top: 14px;
  border-radius: 8px;
  overflow: hidden;
}

.cell-list .van-cell {
  height: 48px;
}

.cell-list .van-cell::after {
  border-bottom: 2px solid #DCDCDC;
}

.cell-list :deep(.van-cell__left-icon) {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.cell-list :deep(.van-cell__title) {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
}

.cell-list :deep(.van-cell__value) {
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
}

.cell-list :deep(.van-cell__right-icon) {
  font-size: 12px;
  color: #1D2129;
}

.cell-check :deep(.van-cell__value), .cell-check :deep(.van-cell__right-icon) {
  color: #FF0033;
}

.cell-label {
  display: inline-block;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #FF0033;
  font-size: 12px;
  font-weight: 400;
  color: #FF0033;
  line-height: 20px;
  padding: 0px 5px;
  margin-left: 15px;
}

.cell-list :deep(.van-checkbox__icon), .cell-list :deep(.van-radio__icon) {
  height: 20px;
  line-height: 20px;
}

.cell-list :deep(.van-checkbox__icon .van-icon), .cell-list :deep(.van-radio__icon .van-icon) {
  width: 20px;
  height: 20px;
  font-size: 16px;
}

.cell-list :deep(.van-checkbox__label) {
  margin-left: 8px;
}

.cell-list .cell-div {
  position: relative;
}

.cell-list .cell-div::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 2px solid #DCDCDC;
  transform: scaleY(.5);
}

.cell-title-img {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.spend-bottom {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
}

.spend-bottom span {
  font-weight: 600;
  color: #FF0033;
}

.bottom-bar .van-button {
  margin: 0px;
  height: 38px;
  background: #4983FE;
  border-radius: 20px;
  min-width: 130px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

</style>
